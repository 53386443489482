@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
.eighth{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin-top: -50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    .title{
        margin-top: 0;
    }
    .faq-list{
        width: 90%;
        padding: 2em 0;
        @include mobile{
            width: 100%;
        }
        .itemBtn{
            @include mobile{
                font-size: 1em;
            }
        }
    }
}