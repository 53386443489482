@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
.sixth{
    font-family: "Montserrat", serif;
    min-height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: white;
    padding: 5rem 0;
    @include mobile{
        padding-top: 2rem;           
    }
    .div-sixth{
        font-family: "Montserrat", serif;
        max-width: 350px;
        max-height: 250px;
        border-radius: 20px;
        padding: 1rem;
        margin: 2rem 2rem;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        &>p{
            margin-top: 1rem;
        }
        &>h1{
            font-size: 1.5rem;
            font-weight: 600;
        }
    }
}