@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
.seventh{
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.section3 {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/keyboard.jpg');
    }
    &>p{
        font-family: "Montserrat", serif;
        font-size: 3rem;
        margin-top: 1rem;
        color: #fff;
        font-weight: 600;
        text-align: center;
        @include mobile{
            font-size: 1.5rem;
            text-align: center;
            padding: 0 1rem;   
            line-height: 1.7rem;    
        }
    }
    .button_div{
        text-align: end;
        margin-top: 8rem;
        @include mobile{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3em;
        }
        &>button{
            background-color: #25D366;
            font-size: 1.5rem;
            padding: 1.3em 1em;
            border-radius: 20px;
            color: #fff;
            @include mobile{
                font-size: 0.8em;
            }
        }
    }
}

// @keyframes moveInCircle {
//     0% {
//       transform: rotate(0deg);
//     }
//     50% {
//       transform: rotate(180deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
  
//   @keyframes moveVertical {
//     0% {
//       transform: translateY(-50%);
//     }
//     50% {
//       transform: translateY(50%);
//     }
//     100% {
//       transform: translateY(-50%);
//     }
//   }
  
//   @keyframes moveHorizontal {
//     0% {
//       transform: translateX(-50%) translateY(-10%);
//     }
//     50% {
//       transform: translateX(50%) translateY(10%);
//     }
//     100% {
//       transform: translateX(-50%) translateY(-10%);
//     }
//   }
  
//   :root {
//     --color-bg1: rgb(8, 10, 15);
//     --color-bg2: rgb(0, 17, 32);
//     --color1: 18, 113, 255;
//     --color2: 107, 74, 255;
//     --color3: 100, 100, 255;
//     --color4: 50, 160, 220;
//     --color5: 80, 47, 122;
//     --color-interactive: 140, 100, 255;
//     --circle-size: 80%;
//     --blending: hard-light;
//   }
  
  
//   * {
//     margin:0;
//     padding:0;
//     outline:none;
//     list-style:none;
//     text-decoration:none;
//     box-sizing:border-box;
//     background: transparent;
//     border:none;
//   }
  
//   html, body {
//     font-family: 'Dongle', sans-serif;
//     margin: 0;
//     padding: 0;
//     height: 100%;
//     width: 100%;
//   }
// .gradient-bg {
//     width: 100vw;
//     height: 100vh;
//     position: relative;
//     overflow: hidden;
//     background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
//     top: 0;
//     left: 0;
  
//     .svgBlur {
//       display: none;
//     }
  
//     .noiseBg {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       z-index: 1;
//       mix-blend-mode: soft-light;
//       opacity: 0.3;
//     }
//     .gradients-container {
//       filter: url(#goo) blur(40px) ;
//       width: 100%;
//       height: 100%;
//     }
  
//     .g1 {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: var(--circle-size);
//       height: var(--circle-size);
//       top: calc(50% - var(--circle-size) / 2);
//       left: calc(50% - var(--circle-size) / 2);
  
//       transform-origin: center center;
//       animation: moveVertical 30s ease infinite;
  
//       opacity: 1;
//     }
  
//     .g2 {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: var(--circle-size);
//       height: var(--circle-size);
//       top: calc(50% - var(--circle-size) / 2);
//       left: calc(50% - var(--circle-size) / 2);
  
//       transform-origin: calc(50% - 400px);
//       animation: moveInCircle 20s reverse infinite;
  
//       opacity: 1;
//     }
  
//     .g3 {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: var(--circle-size);
//       height: var(--circle-size);
//       top: calc(50% - var(--circle-size) / 2 + 200px);
//       left: calc(50% - var(--circle-size) / 2 - 500px);
  
//       transform-origin: calc(50% + 400px);
//       animation: moveInCircle 40s linear infinite;
  
//       opacity: 1;
//     }
  
//     .g4 {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: var(--circle-size);
//       height: var(--circle-size);
//       top: calc(50% - var(--circle-size) / 2);
//       left: calc(50% - var(--circle-size) / 2);
  
//       transform-origin: calc(50% - 200px);
//       animation: moveHorizontal 40s ease infinite;
  
//       opacity: 0.7;
//     }
  
//     .g5 {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: calc(var(--circle-size) * 2);
//       height: calc(var(--circle-size) * 2);
//       top: calc(50% - var(--circle-size));
//       left: calc(50% - var(--circle-size));
  
//       transform-origin: calc(50% - 800px) calc(50% + 200px);
//       animation: moveInCircle 20s ease infinite;
  
//       opacity: 1;
//     }
  
//     .interactive {
//       position: absolute;
//       background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
//       mix-blend-mode: var(--blending);
  
//       width: 100%;
//       height: 100%;
//       top: -50%;
//       left: -50%;
  
//       opacity: 0.7;
//     }
//   }