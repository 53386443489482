div.parallax-image1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('./assets/two.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.parallax-image2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/stone.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

div.parallax-image3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/keyboard.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sticky {
  margin-top: -100vh;
  width: 100%;
  height: 100vh;
  background: linear-gradient(45deg, red, blue);
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: -1;
}

div.section-header {
  position: relative;
  z-index: 2
}

div.section {
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 100vh;
  padding: 3em calc(20vw - 70px);
  z-index: 1
}
