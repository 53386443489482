@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
.third{
    font-family: "Montserrat", serif;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-top: -50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    .title{
        margin-top: 5rem;
        white-space: pre-line;
        height: 150px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 3rem;
        color: #4b4b4b;
        font-weight: 600;
        @include mobile{
            font-size: 2rem;
            height: 100px;  
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 85%;
        padding: 0 5rem;
        @include mobile{
            padding: 0;
            width: 90%;
            align-items: center;
        }
        .card-third{
            font-family: "Montserrat", serif;
            max-width: 600px;
            max-height: 300px;
            border-radius: 20px;
            padding: 2rem;
            margin: 2rem 2rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            @include mobile{
                max-height: 400px;
            }
            &>p{
                margin-top: 1rem;
            }
            &>h1{
                font-size: 1.5rem;
                font-weight: 600;
            }
        }
        .one{
            &>div{
                color: #4b4b4b;
                font-size: 1.5rem;
                max-width: 550px;
                @include mobile{
                    max-width: 98%;
                    font-size: 1.2rem;
                }
                &>strong{
                    color: #4b4b4b
                }
            }
        }
        .two{
            margin-top: 2rem;
            &>div{
                color: #4b4b4b;
                font-size: 1.5rem;
                max-width: 550px;
                @include mobile{
                    max-width: 98%;
                    font-size: 1.2rem;
                }
                &>strong{
                    color: #4b4b4b
                }
            }
        }
        .three{
            margin-top: 2rem;   
            &>div{
                color: #4b4b4b;
                font-size: 1.5rem;
                max-width: 550px;
                @include mobile{
                    max-width: 98%;
                    font-size: 1.2rem;
                }
                &>strong{
                    color: #4b4b4b
                }
            }
        }
    }
    .button_div{
        text-align: end;
        margin-top: 4rem;
        margin-bottom: 4rem;
        @include mobile{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3em;
            padding: 1rem;
        }
        &>button{
            background-color: #25D366;
            font-size: 1.5rem;
            padding: 1.3em 1em;
            border-radius: 20px;
            color: white;
            @include mobile{
                padding: 1.7rem 1rem;
                font-size: 1rem;
                text-wrap: wrap;
            }
        }
    }
}