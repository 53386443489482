@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};

.fourth{
    font-family: "Montserrat", serif;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.page-container {
        position: relative;
        overflow: hidden; /* Evita que se desborden elementos */
        width: 100%;
        height: 100vh; /* Asegura que ocupe toda la altura de la página */
    }
      
    &.page-container::before {
        content: '';
        position: fixed; 
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/stone.jpg');
        background-position: center;
        transform: translateZ(0); /* Mejora rendimiento en móviles */
        z-index: -1; /* Coloca detrás del contenido */
        pointer-events: none; /* Evita interferencias */
    }
    .title{
        margin-top: 5rem;
        white-space: pre-line;
        height: 150px;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        color: #fff;
        @include mobile{
            padding: 0 1rem;
            height: 100px;
            font-size: 2rem;
        }
    }
    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @include mobile{
            padding: 0 2rem;
        }
        .one{
            margin-top: 0rem;
            &>div{
                color: #fff;
                font-size: 1.5rem;
                max-width: 750px;
                &>strong{
                    color: #fff
                }
            }
        }
        .two{
            margin-top: 2rem;
            &>div{
                color: #fff;
                font-size: 1.5rem;
                max-width: 750px;
                &>strong{
                    color: #fff
                }
            }
        }
        .three{
            margin-top: 2rem;
            &>div{
                color: #fff;
                font-size: 1.5rem;
                max-width: 750px;
                &>strong{
                    color: #fff
                }
            }
        }
        .four{
            margin-top: 2rem;
            &>div{
                color: #fff;
                font-size: 1.5rem;
                max-width: 750px;
                &>strong{
                    color: #fff
                }
            }
        }
    }
    .button_div{
        text-align: end;
        margin-top: 7rem;
        margin-bottom: 4rem;
        @include mobile{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3em;
        }
        &>button{
            background-color: #25D366;
            font-size: 1.5rem;
            padding: 1.3em 1em;
            border-radius: 20px;
            color: white;
            @include mobile{
                margin-bottom: 1rem;
                font-size: 1.2em;
            }
        }
    }
}