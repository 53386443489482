// #E20E25 ROJO MAZDA
@mixin pc {
	@media screen and (min-width: 1366px) {
		@content
	}
};
@mixin mobile{
	@media screen and (max-width: 1224px) {
		@content
	}
};
.headline {
	font-family: "Montserrat", serif;
	font-size: 4em !important;
	color: #fff !important;
	text-shadow: #000 2px 0 10px;
	font-weight: 600 !important;
	@include mobile{
		font-size: 2em !important;
	}
	.span-title{
		font-size: 3rem;
		@include mobile{
			font-size: 1.5rem !important;
		}
	}
}

.helvet {
	font-family: "Montserrat", serif;
}

  
.inset {
	position: absolute;
	inset: 0;
}
.white {
	color: white;
}
.gray {
	color: #446;
}
  
.full {
	height: 100dvh;
}
  
.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title {
	margin-top: 5em;
	text-align: center;
	@include mobile{
		margin-top: 9em;
	}
}

.background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100dvw;
	height: 100dvh;
	object-fit: cover;
	transform: translate(-50%, -50%);
	z-index: -1;	
}

.subtitlediv{
	display: flex;
	height: 100dvh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 10em;
	@include mobile{
		margin-top: 9em;
	}
	.first{
		display: flex;
		justify-content: flex-start;
		width: 70%;
		font-size: 2em;
		color: #fff;
		text-shadow: #000 1px 0 10px;
		font-weight: 600 !important;
		@include mobile{
			font-size: 1.2em;
		}
	}
	.sub_header{
		display: flex;
		flex-direction: row;
		width: 70%;
		font-size: 1.7em;
		margin-top: 2em;
		@include mobile{
			flex-direction: column;
			font-size: 1.2em;
		}
		&>div{
			flex: 1;
			display: flex;
			justify-content: flex-end;
			text-shadow: #000 1px 0 10px;
			color: #fff;
			font-weight: 600 !important
		}
		.button_div{
			text-align: end;
			@include mobile{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 3em;
			}
			&>button{
				background-color: #25D366;
				font-size: 1em;
				padding: 1.3em 1em;
				border-radius: 20px;
				color: white;
				@include mobile{
					font-size: 0.8em;
				}
			}
		}
	}
}

.subtitle {
	margin-top: 120px;
}

.gradient {
	background: linear-gradient(rgba(14, 62, 151, 0.5) 50%, black);
}
  